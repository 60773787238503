body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alsoBuy-carousel.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events {
  padding: 30px 25px;
}
.swiper-button-next {
  right: 0 !important;
  background-image: url("../public/icons/next.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  left: 0 !important;
  background-image: url("../public/icons/previous.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}
.chakra-popover__arrow {
  background-color: #2745ac !important;
}
.css-ddil6j {
  background-color: #EDEDED !important;
}
.css-ddil6j[data-checked] {
  background: #E2368F !important;
}
.css-168ld8k {
  background-color: #EDEDED !important;
}
.css-168ld8k[data-checked] {
  background: #E2368F !important;
}
